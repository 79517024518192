<template lang="pug">
page-structure-element(
  :element="element"
  :defaultImage="getBackground(element)"
  :interactions="interactions"
  @clicked="selectRow(element.uid)"
  @mouseenter="enterRow(element)"
  @mouseleave="leaveRow(element)"
) {{ $t('blockWithXCol', { columnCnt: columnCnt, plural: columnCnt > 1 ? 's' : '' }) }}
</template>

<script>
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureElement from '../PageStructureElement.vue';

  export default {
    components: { PageStructureElement },
    mixins: [pageStructureMixin],
    props: {
      element: {
        type: Object,
        required: true,
      },
      interactions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        defaultImage: {
          background: 'transparent',
        },
      };
    },
    computed: {
      columnCnt() {
        return this.element.data.columnSplit.length;
      },
    },
  };
</script>
